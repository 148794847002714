import React, { Component } from "react";
import Modal from "./Modal";
import logo from "../Assets/Icons/map-marker.png";
import logo_black from "../Assets/Icons/map-marker-black.png";
import Geocode from "react-geocode";
import axios from "axios";
const publicIp = require('public-ip');

var region = null;
var location = null;
var city = "Select City";
var province = "";
var country = "Canada";
var postal = null;

if (sessionStorage.getItem("location")) {
  location = sessionStorage.getItem("location");
} else {
  location = "Toronto";
}
if (sessionStorage.getItem("region")) {
  region = sessionStorage.getItem("region");
} else {
  //region = "gta";
}

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyBk30RGZX1CCvyRA79tt8OCKfapXktL3E0");
Geocode.setLanguage("en");
Geocode.setRegion("CA");

const ottawa = {
  latitude: 45.3342894,
  longitude: -75.7164072,
};
const toronto = {
  latitude: 43.8314437,
  longitude: -79.5223975,
};
const vancouver = {
  latitude: 49.26388,
  longitude: -123.11792,
};
const calgary = {
  latitude: 51.01060,
  longitude: -114.12609,
};

function haversine_distance(pos_lat, pos_long, lat, long) {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = pos_lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (long - pos_long) * (Math.PI / 180); // Radian difference (longitudes)
  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
  return d;
}

Number.prototype.toRad = function () {
  return (this * Math.PI) / 180;
};

function haversine_distance_new(pos_lat, pos_long, lat, long) {
  var lat1 = pos_lat;
  var lon1 = pos_long;
  var lat2 = lat;
  var lon2 = long;

  var R = 6371; // km
  //has a problem with the .toRad() method below.
  var x1 = lat2 - lat1;
  var dLat = x1.toRad();
  var x2 = lon2 - lon1;
  var dLon = x2.toRad();
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1.toRad()) *
      Math.cos(lat2.toRad()) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  return d;
}

function hav_distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

function calculateRegion(lat, lng) {
  console.log("Latitude is :", lat);
  console.log("Longitude is :", lng);

  let distance = [];

  distance["ottawa"] = hav_distance(
    ottawa.latitude,
    ottawa.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from ottawa is " + distance["ottawa"]);

  distance["gta"] = hav_distance(
    toronto.latitude,
    toronto.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from Toronto is " + distance["gta"]);

  distance["vancouver"] = hav_distance(
    vancouver.latitude,
    vancouver.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from vancouver is " + distance["vancouver"]);

  distance["calgary"] = hav_distance(
    calgary.latitude,
    calgary.longitude,
    lat,
    lng,
    "K"
  );
  console.log("distance from calgary is " + distance["calgary"]);
  

  let distancesValues = [distance["ottawa"],distance["gta"],distance["vancouver"],distance["calgary"]];
  let distances = ['ottawa','gta','vancouver','calgary'];
  console.log("distances",distances);

  let value = Array.min(distancesValues);
  let nearest = getIndexLowest(distancesValues);

  console.log("value distance is " + value);
  console.log("nearest distance is " + nearest);

  return distances[nearest];

}
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
function getNearest(array){
  let value,value2 = 0;
  let selKey;
  // for(var key in array){
  //   for(var key2 in array[key]){
  //     console.log("Key: " + key2)      
  //     value2 = array[key][key2];
  //     console.log("Value2: " + value2);
  //     if(parseFloat(value2) < parseFloat(value)){ //if this value is less then previous
  //       console.log("Value: " + value);
  //       selKey = key2;
  //       continue;
  //     }
  //     value = value2;
  //   }
  // }
  // return selKey;
  array.forEach(element => { 
    //value = element.value;
    console.log(element);
  });
}

function getIndexLowest(temp){
  var index = 0;
  var value = temp[0];
  for (var i = 0; i < temp.length; i++) {
    if (temp[i] < value) {
      value = temp[i];
      index = i;
    }
  }
  return index;
}

Array.min = function(array){
  return Math.min.apply(Math, array);
};

function checkForArea(rad, fromPosition, toPosition) {
  let distance = 100;
  //let distance = locationA.distanceTo(locationB);
  if (distance / 1000 <= rad) return true;
  else return false;
}

class GeoLocation extends Component {
  constructor(props) {
    super(props);
    this.openLocateModal = this.openLocateModal.bind(this);
    this.changeRegionData = this.changeRegionData.bind(this);
    this.state = {
      data: this.props.data,
      isModalOpen: false,
      location: location,
      region: null,
      activeClasses: [false, false],
      city: city,
      province: province,
      postal: postal,
    };
  }

  openLocateModal() {
    this.toggleModal();
  }


  async checkIPGeolocate() {
    console.log('IP Address Fallback')

     //fallback to IP address API locate
     publicIp.v4().then(response => { 
       
       console.log(response);

       axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=beaca91296ad435bb9b5a231fe1234f4&ip="+response)
       .then(async data => { 
         console.log('Axios get return https://api.ipgeolocation.io/ipgeo?apiKey=beaca91296ad435bb9b5a231fe1234f4&ip='+response)
         console.log(data)
         
         let latitude = data.data.latitude;
         let longitude = data.data.longitude;
         
         region = await calculateRegion(data.data.latitude, data.data.longitude);
         this.updateCity(data.data.latitude, data.data.longitude);          
         
         console.log('geolocated region is ' + region);
         this.changeRegionData(region);
 
         this.setState({
             data: window.$json,
             lat: latitude, 
             lng: longitude
           },() => console.log(latitude, longitude)
         );
       });

     })
 }

  async changeRegionData(region) {
    let json = null;
    await import("../Assets/Data/" + region + ".json").then(function (
      response
    ) {
      window.$json = response;
      json = response;
      console.log("changeRegionData called for " + region);
      console.log(response);
      switch (region) {
        case "ottawa":
          location = "Ottawa";
          city = "Ottawa";
          province = "Ontario";
          break;
        case "gta":
          location = "Greater Toronto Area";
          city = "Greater Toronto Area";
          province = province || "Ontario";
        break;
        case "calgary":
          location = "Calgary";
          city = "Calgary";
          province = province || "Alberta";
        break;
        case "vancouver":
          location = "Vancouver";
          city = "Vancouver";
          province = province || "British Columbia";
        break;
      }
    });
    console.log("Finished Json Import");
    sessionStorage.setItem("location", location);
    sessionStorage.setItem("region", region);
    this.props.updateFunc(window.$json);
    this.setState({ city: city, province: province });
    // this.setState({ state: this.state });
  }

  handleRegionClick = (value) => {
    console.log(value);
    this.changeRegionData(value);
  };

  toggleModal = () => {
    this.setState({
      header: "Change Your City",
      isModalOpen: !this.state.isModalOpen,
    });
  };

  onUpdate = async (e) => {
    let value = document.querySelector('[name="postal_code"]').value;
    this.runGeocode(value);
    this.toggleModal();
  };

  runGeocode = async (value) => {
    if (value.length > 0) {
      sessionStorage.setItem("postal", value);
      this.setState({ postal: value });
    }

    let lat,
      lng = null;
    await Geocode.fromAddress(value).then(
      async (response) => {
        lng = response.results[0].geometry.location.lng;
        lat = response.results[0].geometry.location.lat;
        console.log(lat, lng);
        this.setState({
          lat: lat,
          lng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );

    region = await calculateRegion(lat, lng);
    console.log("geolocated region is " + region);
    this.changeRegionData(region);
    this.updateCity(lat, lng);
  };

  clickToggle = (index) => {
    const newClasses = [false, false];
    newClasses[index] = true;
    this.setState({
      activeClasses: newClasses,
    });
    console.log(this.state.activeClasses);
  };

  render() {
    const activeClasses = this.state.activeClasses.slice();
    console.log("render GeoLocation");
    console.log(this.props.data);
    return (
      <>
        <div
          onClick={this.openLocateModal}
          className="message mt-5 geolocation-tab"
        >
          <img src={logo} />
          <span>{this.state.city + " " + this.state.province}</span>
        </div>
        <Modal
          show={this.state.isModalOpen}
          header={this.state.header}
          image={logo_black}
          onClose={this.toggleModal}
        >
          <div className="d-flex postal_input">
            <label>Enter Postal Code</label>
            <input
              type="text"
              name="postal_code"
              maxLength="7"
              minLength="6"
              pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
              className="postal_input"
              placeholder="Postal Code"
            />
            <a className="blue-btn" onClick={this.onUpdate}>
              SUBMIT
            </a>
          </div>

          {/* <div className="flexBox">
            <ul className="regionList">
              <li
                className={this.state.activeClasses[0] ? "active" : null}
                onClick={(event) => {
                  this.handleRegionClick("ottawa");
                  this.clickToggle(0);
                }}
                data-region="ottawa"
              >
                Ottawa
              </li>
              <li
                className={this.state.activeClasses[1] ? "active" : null}
                onClick={(event) => {
                  this.handleRegionClick("gta");
                  this.clickToggle(1);
                }}
                data-region="gta"
              >
                Greater Toronto
              </li>
            </ul>
          </div> */}
        </Modal>
      </>
    );
  }
  setStateData(json) {
    this.setState({ data: json });
    console.log(json);
  }
  updateCity = (lat, long) => {
    console.log("updating city function");
    Geocode.fromLatLng(lat, long).then(
      (response) => {
        const address = response.results[0].formatted_address;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                this.setState({ city: city });
                break;
              case "administrative_area_level_1":
                province = response.results[0].address_components[i].long_name;
                this.setState({ province: province });
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        console.log(city, province, country);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  componentDidMount() {
    if (sessionStorage.getItem("postal")) {
      let value = sessionStorage.getItem("postal");
      this.runGeocode(value);
    } else if (region && window.$json !== null) {
      this.changeRegionData(region);
    } else {
      this.checkIPGeolocate();
      if ("geolocation" in navigator) {

        let success = async (position) => {
          console.log(position);

          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;

          region = await calculateRegion(
            position.coords.latitude,
            position.coords.longitude
          );
          this.updateCity(position.coords.latitude, position.coords.longitude);

          this.changeRegionData(region);

          this.setState({
            data: window.$json,
            lat: latitude,
            lng: longitude,
          });
        };

        let error = () => {
          this.checkIPGeolocate();
        }
        navigator.geolocation.enableHighAccuracy = true;
        navigator.geolocation.getCurrentPosition(success, error);
      } else {
        console.log("Not Available");
      }
    }
  }
}

export default GeoLocation;
